var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"items":_vm.items,"headers":[
      { text: 'Mannschaft', value: 'team', sortable: false },
      { text: 'Turner', value: 'person', sortable: false },
      { text: 'E', value: 'e', sortable: false },
      { text: 'D', value: 'd', sortable: false },
      { text: 'C', value: 'c', sortable: false },
      { text: 'B', value: 'b', sortable: false },
      { text: 'A', value: 'a', sortable: false },
      { text: 'NE', value: 'ne', sortable: false },
      { text: 'EG', value: 'eg', sortable: false },
      { text: 'Sprung', value: 'sprung', sortable: false },
      { text: 'E1', value: 'e1', sortable: false },
      { text: 'E2', value: 'e2', sortable: false },
      { text: 'E3', value: 'e3', sortable: false },
      { text: 'E4', value: 'e4', sortable: false },
      { text: 'OK', value: 'ok', sortable: false },
      { text: 'End', value: 'final', sortable: false } ],"hide-default-footer":"","items-per-page":-1},on:{"click:row":_vm.open},scopedSlots:_vm._u([{key:"item.team",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.team.name)+" ")]}},{key:"item.person",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("person")(item.person))+" ")]}},{key:"item.e",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("int")(item.result.e))+" ")]}},{key:"item.d",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("int")(item.result.d))+" ")]}},{key:"item.c",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("int")(item.result.c))+" ")]}},{key:"item.b",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("int")(item.result.b))+" ")]}},{key:"item.a",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("int")(item.result.a))+" ")]}},{key:"item.ne",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("int")(item.result.ne))+" ")]}},{key:"item.eg",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("float1")(item.result.eg))+" ")]}},{key:"item.sprung",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.sprung(item.result._sprung))+" ")]}},{key:"item.e1",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("float1")(item.result.e1))+" ")]}},{key:"item.e2",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("float1")(item.result.e2))+" ")]}},{key:"item.e3",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("float1")(item.result.e3))+" ")]}},{key:"item.e4",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("float1")(item.result.e4))+" ")]}},{key:"item.ok",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("float1")(item.result.ok))+" ")]}},{key:"item.final",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("float2")(item.result.final))+" ")]}}])}),_c('base-material-dialog',{attrs:{"icon":"far fa-poll-h","title":("Wertung eingeben: " + _vm.dname),"sub-title":(_vm.pname + " (" + _vm.tname + ")"),"color":"primary"},on:{"esc":_vm.close},model:{value:(_vm.result.open),callback:function ($$v) {_vm.$set(_vm.result, "open", $$v)},expression:"result.open"}},[_c('ergebnis',{attrs:{"id":_vm.result.id,"did":_vm.did,"e":_vm.e,"df":_vm.df},on:{"del":_vm.del,"save":_vm.save,"close":_vm.close}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }